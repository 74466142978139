import { toArray } from '@/utils/array'
import router from '@/router/index'

const tagIdsQueryKeys = ['t'] as const

type TagIdsQueryKeys = typeof tagIdsQueryKeys[number]

export type TagIdsQueryItem = Record<TagIdsQueryKeys, string | string[]>

export const toTagIdsQuery = (tag_id: number | string): TagIdsQueryItem => {
  return {
    [tagIdsQueryKeys[0]]: `${tag_id}`
  }
}

export const getTagIdsQuery = (): string[] => {
  const queryClone = { ...router.currentRoute.value.query }
  const tagIdsQuery = tagIdsQueryKeys[0] in queryClone ? (queryClone as TagIdsQueryItem)[tagIdsQueryKeys[0]] : ''
  return toArray(tagIdsQuery)
}
