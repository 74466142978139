import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import { RootState } from '@/types/store/index'
import { LayoutState } from '@/types/store/layout'
import { SuggestAttributes } from '@/types/models/suggest'
import { axios, ApiResponse } from '@/plugins/axios'

const initialValues: LayoutState = {
  sidebar: {
    open: false
  },
  searchHistory: {
    open: false
  },
  pcSearching: {
    on: false
  },
  suggests: undefined
}

const state = (): LayoutState => (initialValues)

const getters: GetterTree<LayoutState, RootState> = {
  getSuggests: (state) => {
    return state.suggests
  }
}

const mutations: MutationTree<LayoutState> = {
  setSidebarState (state, open: boolean) {
    state.sidebar.open = open
  },
  setsearchHistoryState (state, open: boolean) {
    state.searchHistory.open = open
  },
  setpcSearchingState (state, onOff: boolean) {
    state.pcSearching.on = onOff
  },
  setSuggests (state, suggests?: SuggestAttributes[]) {
    state.suggests = suggests
  },
}

const actions: ActionTree<LayoutState, RootState> = {
  switchSidebarOpen ({ state, commit }) {
    commit('setSidebarState', !state.sidebar.open)
  },
  switchsearchHistoryOpen ({ state, commit }) {
    commit('setsearchHistoryState', !state.searchHistory.open)
  },
  pcSearchingOff ({ commit }) {
    commit('setpcSearchingState', false)
  },
  pcSearchingOn ({ commit }) {
    commit('setpcSearchingState', true)
  },
  fetchSuggests: async ({ state, commit }) =>{
    if (! state.suggests) {
      const response = await axios.get<ApiResponse<SuggestAttributes[]>>('/search/suggestions')
      commit('setSuggests', response.data.payload)
    }
  },
}

export const layout: Module<LayoutState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
