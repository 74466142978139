import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import { RootState } from '@/types/store/index'
import { SearchState } from '@/types/store/search'
import { SearchHistoryAttributes } from '@/types/models/search-history'
import { axios, ApiResponse } from '@/plugins/axios'
import { getSearchQuery } from '@/types/forms/search-word'

const initialValues: SearchState = {
  searchHistories: undefined,
  search_word: '',
}

const state = (): SearchState => (initialValues)

const getters: GetterTree<SearchState, RootState> = {
  getSearchWord: (state) => {
    return state.search_word
  },
  getSearchHistories: (state) => {
    return state.searchHistories
  },
}

const mutations: MutationTree<SearchState> = {
  setSearchHistories (state, searchHistories?: SearchHistoryAttributes[]) {
    state.searchHistories = searchHistories
  },
  setSearchWord (state, search_word: string) {
    state.search_word = search_word
  },
}

const actions: ActionTree<SearchState, RootState> = {
  fetchSearchHistories: async ({ commit }) =>{
    const response = await axios.get<ApiResponse<SearchHistoryAttributes[]>>('/search/history')
    commit('setSearchHistories', response.data.payload)
  },
  fetchSearchWord: async ({ commit }) =>{
    commit('setSearchWord', getSearchQuery())
  },
  postSearch: async ({ commit }, searchWord:string) =>{
    await new Promise<void>((resolve, reject) => {
      axios
        .post('/search/history', {
          search_word: searchWord
        })
        .then(response => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export const search: Module<SearchState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
