import { LocationQueryRaw } from 'vue-router'
import router from '@/router/index'

const SearchQueryKeys = ['search'] as const

type SearchQueryKeys = typeof SearchQueryKeys[number]

type SearchQueryItem = Record<SearchQueryKeys, string>

export const getSearchQuery = (): string => {
  const queryClone = { ...router.currentRoute.value.query }
  const strSearch = SearchQueryKeys[0] in queryClone ? (queryClone as SearchQueryItem)[SearchQueryKeys[0]] : ''
  return strSearch
}

const toSearchQuery = (search: string): SearchQueryItem => {
  return { [SearchQueryKeys[0]]: `${String(search)}` }
}

export const addSearchQuery = (search?: string): LocationQueryRaw => {
  const queryClone = { ...router.currentRoute.value.query }
  if (typeof search === 'string') {
    // router.push({ path: '/video', query: { search: `${search}` } })
    return { ...queryClone, ...toSearchQuery(search) }
  } else {
    delete queryClone[SearchQueryKeys[0]]
    return queryClone
  }
}
