import { RouteRecordRaw } from 'vue-router'
import { authMiddleware } from './navigation-guards'

import Terms from '@/pages/Terms.vue'
// import Login from '@/pages/Login.vue'
import ForgotPassword from '@/pages/ForgotPassword.vue'
import ResetPassword from '@/pages/ResetPassword.vue'
import Account from '@/pages/Account.vue'
import Contact from '@/pages/Contact.vue'
import AccountChangeEmail from '@/pages/AccountChangeEmail.vue'
import AccountConfirmEmail from '@/pages/AccountConfirmEmail.vue'
import AccountChangeName from '@/pages/AccountChangeName.vue'
import AccountChangeNickname from '@/pages/AccountChangeNickname.vue'
import AccountChangePassword from '@/pages/AccountChangePassword.vue'
import AccountChangeMode from '@/pages/AccountChangeMode.vue'
import NoticeList from '@/pages/NoticeList.vue'
import NoticeDetails from '@/pages/NoticeDetails.vue'
import PageDetails from '@/pages/PageDetails.vue'
import VideoList from '@/pages/VideoList.vue'
import VideoBrowsingHistory from '@/pages/WatchHistory.vue'
import VideoBookmarkList from '@/pages/VideoBookmarkList.vue'
import SeminarList from '@/pages/SeminarList.vue'
import SeminarDetails from '@/pages/SeminarDetails.vue'
import RecommendCourseList from '@/pages/RecommendCourseList.vue'
import Calendar from '@/pages/Calendar.vue'
import QaCategoryList from '@/pages/QaCategoryList.vue'
import QaList from '@/pages/QaList.vue'
import Schedule from '@/pages/Schedule.vue'
import Facebook from '@/pages/Facebook.vue'
import MyPage from '@/pages/MyPage.vue'
import OnlyVideos from '@/pages/OnlyVideos.vue'

import WellLabo from '@/pages/WellLabo.vue'
import CourseDetails from '@/pages/CourseDetails.vue'
import VideoDetails from '@/pages/VideoDetails.vue'
import CourseList from '@/pages/CourseList.vue'
import WatchHistory from '@/pages/WatchHistory.vue'

import NotFound from '@/pages/NotFound.vue'

export const WELL_LABO_HOME_PATH = '/well_labo'

export const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: WELL_LABO_HOME_PATH },
  { 
    path: '/well_labo', 
    component: WellLabo, 
    name: 'well_labo',
    beforeEnter: authMiddleware
  },
  {
    path: WELL_LABO_HOME_PATH,
    name: 'WellLabo',
    component: WellLabo,
    beforeEnter: authMiddleware
  },
  {
    path: '/well_labo/course_list',
    name: 'courseList',
    component: CourseList,
    beforeEnter: authMiddleware
  },
  {
    path: '/notice',
    name: 'NoticeList',
    component: NoticeList,
    beforeEnter: authMiddleware
  },
  {
    path: '/notice/:notice_id',
    name: 'NoticeDetails',
    component: NoticeDetails,
    beforeEnter: authMiddleware
  },
  {
    path: '/well_labo/video/:content_id',
    name: 'VideoDetails',
    component: VideoDetails,
    beforeEnter: authMiddleware
  },
  {
    path: '/well_labo/course/:course_id',
    name: 'CourseDetails',
    component: CourseDetails,
    beforeEnter: authMiddleware
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/well_labo/video',
    name: 'VideoList',
    component: VideoList,
    beforeEnter: authMiddleware
  },
  {
    path: '/well_labo/watch_history',
    name: 'WatchHistory',
    component: WatchHistory,
    beforeEnter: authMiddleware
  },
  {
    path: '/well_labo/my_page',
    name: 'MyPage',
    component: MyPage,
    beforeEnter: authMiddleware
  },
  {
    path: '/well_labo/only_videos',
    name: 'OnlyVideos',
    component: OnlyVideos,
    beforeEnter: authMiddleware
  },
  {
    path: '/well_labo/facebook',
    name: 'Facebook',
    component: Facebook,
    beforeEnter: authMiddleware
  },
  {
    path: '/well_labo/schedule',
    name: 'Schedule',
    component: Schedule,
    beforeEnter: authMiddleware
  },
  
  // *--- NotFound ---*
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
]
