import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import { RootState } from '@/types/store/index'
import { CourseState } from '@/types/store/course'
import { SubscContentsAttributes } from '@/types/models/subsc-contents'
import { SubscContentCourseSubtitleAttributes } from '@/types/models/subsc-content-course-subtitle'
import { SubscContentCourseAttributes } from '@/types/models/subsc-content-course'
import { axios, ApiResponse } from '@/plugins/axios'
import { toArray } from '@/utils/array'
import { toSlice } from '@/utils/slice'

// const initialValues: CourseState = {
//   latestCourses: undefined,
//   courseDetails: undefined
// }

// const state = (): CourseState => (initialValues)

const state = (): CourseState => ({
  latestCourses: undefined,
  oldCourses: undefined,
  courseDetails: undefined,
  showCourseDetail: false,
  showCourseId: 0,
  courses: undefined,
  contents: undefined,
  userWatchHistories: undefined,
  filterVideos: undefined,
  rankingCourses: undefined,
  LatestOnlyVideos: undefined,
})

const getters: GetterTree<CourseState, RootState> = {
  getLatestCourses: (state) => () => {
    return state.latestCourses
  },
  getLatestOnlyVideoHome: (state) => (show: number) => {
    return state.LatestOnlyVideos?.slice(0, show)
  },
  getCourses: (state) => (showCourseId: number) => {
    return state.latestCourses?.find((course: any) => course.id === showCourseId)
  },
  getContents: (state) => (contentId: number) => {
    return state.latestCourses?.find((course: any) => course.id === contentId)
  },
  getWatchHistoriesHome: (state) => (show: number) => {
    return state.userWatchHistories?.slice(0, show)
  },
  getWatchHistories: (state) => () => {
    return state.userWatchHistories
  },
  getAllVideos: (state) => () => {
    const videos = <any>[]
    state.latestCourses?.forEach((seasons: any) => {
      seasons.subsc_content_course_subtitles.forEach((courses: any) => {
        courses.subsc_contents.forEach((contents: any) => {
          videos.push(contents)
        })
      })
    })
    state.oldCourses?.forEach((seasons: any) => {
      seasons.subsc_content_course_subtitles.forEach((courses: any) => {
        courses.subsc_contents.forEach((contents: any) => {
          videos.push(contents)
        })
      })
    })
    return videos
  },
  filterVideosBySearchWord: (state) => (search_word: string): any[] => {
    let result = [...toArray(state.filterVideos)]
    const slicedSearchWords = toSlice(search_word)

    slicedSearchWords.forEach((slicedSearchWord) => {
      const searched_titles = result.filter((item: any) => {
        return item.title?.match(slicedSearchWord)
      })
      const searched_tags = result.filter((item: any) => {
        return toArray(item.subsc_tags).find(tag => tag.name?.match(slicedSearchWord))
      })
      result = Array.from(new Set([...searched_titles, ...searched_tags]))
    })
    return result
  },
  filterVideosByTagIds: (state) => (tagIds: number[] | string[]): any[] => {
    let result = [...toArray(state.filterVideos)]
    tagIds.forEach(tag_id => {
      result = result.filter((item: any) => {
        const tag = toArray(item.tags).find(tag => tag.id === Number(tag_id))
        return !!tag
      })
    })
    return result
  },
  getRankingCourses: (state) => () => {
    return state.rankingCourses?.slice(0, 5)
  },
}

const mutations: MutationTree<CourseState> = {
  setLatestCourses(state, latestCourses) {
    state.latestCourses = latestCourses
  },
  setOldCourses(state, oldCourses) {
    state.oldCourses = oldCourses
  },
  setCourses(state, courses) {
    state.courses = courses
  },
  setContents(state, contents) {
    state.contents = contents
  },
  openShowCourseDetail(state) {
    state.showCourseDetail = true
  },
  closeShowCourseDetail(state) {
    state.showCourseDetail = false
  },
  setShowCourseId(state, showCourseId) {
    state.showCourseId = showCourseId
  },
  setUserWatchHistories(state, userWatchHistories) {
    state.userWatchHistories = userWatchHistories
  },
  setLatestOnlyVideos(state, LatestOnlyVideos) {
    state.LatestOnlyVideos = LatestOnlyVideos
  },
  setFilterVideos(state, filterVideos) {
    state.filterVideos = filterVideos
  },
  setRankingData(state, rankingCourses) {
    state.rankingCourses = rankingCourses
  },
}

const actions: ActionTree<CourseState, RootState> = {
  fetchCourses: async ({ commit }, course_id: number) => {
    const response = await axios.post('/well_labo/fetchSpecificCourse', { 'course_id': course_id })
    commit('setCourses', response.data)
  },
  fetchContents: async ({ commit }, content_id: number) => {
    const response = await axios.post('/well_labo/fetchSpecificContent', { 'content_id': content_id })
    commit('setContents', response.data)
  },
  fetchLatestCourses: async ({ commit }) => {
    const response = await axios.get('/well_labo/fetchLatestCourses')
    // await commit('setLatestCourses', response.data)
    commit('setLatestCourses', response.data)
  },
  fetchOldCourses: async ({ commit }) => {
    const response = await axios.get('/well_labo/fetchOldCourses')
    commit('setOldCourses', response.data)
  },
  fetchUserWatchHistories: async ({ commit }) => {
    const response = await axios.get('/well_labo/fetchUserWatchHistories')
    commit('setUserWatchHistories', response.data)
  },
  filterVideos: async ({ state, commit }) => {
    const videos = <SubscContentsAttributes[]>[]
    state.latestCourses?.forEach((seasons: SubscContentCourseAttributes) => {
      seasons.subsc_content_course_subtitles.forEach((courses: SubscContentCourseSubtitleAttributes) => {
        courses.subsc_contents.forEach((contents: SubscContentsAttributes) => {
          videos.push(contents)
        })
      })
    })
    state.oldCourses?.forEach((seasons: SubscContentCourseAttributes) => {
      seasons.subsc_content_course_subtitles.forEach((courses: SubscContentCourseSubtitleAttributes) => {
        courses.subsc_contents.forEach((contents: SubscContentsAttributes) => {
          videos.push(contents)
        })
      })
    })
    commit('setFilterVideos', videos)
  },
  fetchRankingData: async ({ commit }) => {
    const response = await axios.get('/well_labo/fetchRankingData')
    commit('setRankingData', response.data)
  },
  fetchOnlyVideos: async ({ commit }) => {
    const response = await axios.get('/well_labo/fetchOnlyVideos')
    commit('setLatestOnlyVideos', response.data)
  },

}

export const course: Module<CourseState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
