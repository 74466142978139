import { LocationQueryRaw } from 'vue-router'
import router from '@/router/index'

const CourseQueryKeys = ['Course'] as const

type CourseQueryKeys = typeof CourseQueryKeys[number]

type CourseQueryItem = Record<CourseQueryKeys, string>

export const getCourseQuery = (): string => {
  const queryClone = { ...router.currentRoute.value.query }
  const strCourse = CourseQueryKeys[0] in queryClone ? (queryClone as CourseQueryItem)[CourseQueryKeys[0]] : ''
  return strCourse
}

export const removeCourseQuery = (): LocationQueryRaw => {
  const queryClone = { ...router.currentRoute.value.query }
  delete queryClone[CourseQueryKeys[0]]
  return queryClone
}

const toCourseQuery = (Course: string): CourseQueryItem => {
  return { [CourseQueryKeys[0]]: `${String(Course)}` }
}

export const addCourseQuery = (Course?: string): LocationQueryRaw => {
  const queryClone = { ...router.currentRoute.value.query }
  if (typeof Course === 'string') {
    // router.push({ path: '/video', query: { Course: `${Course}` } })
    return { ...queryClone, ...toCourseQuery(Course) }
  } else {
    delete queryClone[CourseQueryKeys[0]]
    return queryClone
  }
}
