import { Module, MutationTree, ActionTree, GetterTree, createLogger } from 'vuex'
import { RootState } from '@/types/store/index'
import { TagState } from '@/types/store/tag'
import { TagAttributes } from '@/types/models/tag'
import { axios, ApiResponse } from '@/plugins/axios'
import { getTagIdsQuery } from '@/types/forms/tag-checkbox'
import { toArray } from '@/utils/array'

const initialValues: TagState = {
  tags: undefined
}

const state = (): TagState => (initialValues)

const getters: GetterTree<TagState, RootState> = {
  sortTagsByQuery: (state) => (): TagAttributes[] => {
    const tagIds = getTagIdsQuery()

    return toArray(state.tags).slice().sort((x, y) => {
      const xIndex = tagIds.indexOf(`${x.id}`)
      const yIndex = tagIds.indexOf(`${y.id}`)
      const xEl = xIndex !== -1 ? xIndex : tagIds.length
      const yEl = yIndex !== -1 ? yIndex : tagIds.length
      return xEl - yEl
    })
  },
  filterdTagList: (state, getters) => (hiddenTagIds:number[]): TagAttributes[] => {
    return getters.sortTagsByQuery().filter((sortedTag:TagAttributes) => {
      return !(hiddenTagIds.includes(sortedTag.id))
    })
  }
}

const mutations: MutationTree<TagState> = {
  setTags (state, tags?: TagAttributes[]) {
    state.tags = tags
  }
}

const actions: ActionTree<TagState, RootState> = {
  fetchTags: async ({ state, commit }) =>{
    if (! state.tags) {
      // const response = await axios.get<ApiResponse<TagAttributes[]>>('/tag')
      const response = await axios.get<ApiResponse<TagAttributes[]>>('/well_labo/getTags')
      commit('setTags', response.data)
    }
  },
}

export const tag: Module<TagState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
