import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import { RootState } from '@/types/store/index'
import { VideoState } from '@/types/store/video'
import { SubscContentsAttributes } from '@/types/models/subsc-contents'
import { SubscContentCourseSubtitleAttributes } from '@/types/models/subsc-content-course-subtitle'
import { SubscContentCourseAttributes } from '@/types/models/subsc-content-course'
import { axios, ApiResponse } from '@/plugins/axios'
import { toArray } from '@/utils/array'
import { toSlice } from '@/utils/slice'
import { VideoAttributes } from '@/types/models/video'
import { getSortQuery } from '@/types/forms/sort-switch'
import { sortBy } from 'lodash'

// const initialValues: CourseState = {
//   latestCourses: undefined,
//   courseDetails: undefined
// }

// const state = (): CourseState => (initialValues)

const state = (): VideoState => ({
  videos: undefined,
  videoDetails: undefined,
})

const getters: GetterTree<VideoState, RootState> = {
  sortVideosByQuery: () => (videos: VideoAttributes[]): VideoAttributes[] => {
    return getSortQuery() ? sortBy(videos, 'released_at').reverse() : sortBy(videos, 'released_at')
  },
  getLatestCourses: (state) => () => {
    return state.videos
  },
  filterVideosBySearchWord: (state) => (search_word: string): any[] => {
    let result = [...toArray(state.videos)]
    const slicedSearchWords = toSlice(search_word)

    slicedSearchWords.forEach((slicedSearchWord) => { 
      const searched_titles = result.filter((item: any) => {
        return item.title?.match(slicedSearchWord)
      })
      const searched_tags = result.filter((item: any) => {
        return toArray(item.subsc_tags).find(tag => tag.name?.match(slicedSearchWord))
      }) 
      result = Array.from(new Set([...searched_titles, ...searched_tags]))
    })
    return result
  },
  filterVideosByTagIds: (state) => (tagIds: number[] | string[]): VideoAttributes[] => {
    let result = [...toArray(state.videos)]
    tagIds.forEach(tag_id => {
      result = result.filter((item: VideoAttributes) => {
        const tag = toArray(item.subsc_tags).find(tag => tag.id === Number(tag_id))
        return !!tag
      })
    })
    return result
  },
}

const mutations: MutationTree<VideoState> = {
  setAllVideos (state, videos) {
    state.videos = videos
  },
  setVideoDetails (state, videoDetails?: VideoAttributes) {
    state.videoDetails = videoDetails
  },
}

const actions: ActionTree<VideoState, RootState> = {
  fetchAllVideos: async ({ commit }) => {
    const response = await axios.get('/well_labo/fetchAllVideos')
    commit('setAllVideos', response.data)
  },
}

export const video: Module<VideoState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
