import { LocationQueryRaw } from 'vue-router'
import router from '@/router/index'

const sortQueryKeys = ['s'] as const

type SortQueryKeys = typeof sortQueryKeys[number]

type SortQueryItem = Record<SortQueryKeys, string>

export const getSortQuery = (): boolean => {
  const queryClone = { ...router.currentRoute.value.query }
  const strSort = sortQueryKeys[0] in queryClone ? (queryClone as SortQueryItem)[sortQueryKeys[0]] : ''
  return strSort === '' ? true : !!(Number(strSort))
}

const toSortQuery = (sort: boolean): SortQueryItem => {
  return { [sortQueryKeys[0]]: `${Number(sort)}` }
}

export const addSortQuery = (sort?: boolean): LocationQueryRaw => {
  const queryClone = { ...router.currentRoute.value.query }
  if (typeof sort === 'boolean') {
    return { ...queryClone, ...toSortQuery(sort) }
  } else {
    delete queryClone[sortQueryKeys[0]]
    return queryClone
  }
}
