import { InjectionKey } from 'vue'
import { createStore, MutationTree, ActionTree, GetterTree, Store, useStore as baseUseStore } from 'vuex'
import { RootState } from '@/types/store/index'

import { base } from './base'
import { user } from './user'
import { notice } from './notice'
import { course } from './course'
import { search } from './search'
import { layout } from './layout'
import { video } from './video'
import { tag } from './tag'

const getters: GetterTree<RootState, RootState> = {}

const mutations: MutationTree<RootState> = {}

const actions: ActionTree<RootState, RootState> = {}

export const key: InjectionKey<Store<RootState>> = Symbol()

export const store = createStore({
  getters,
  mutations,
  actions,
  modules: {
    base,
    user,
    notice,
    course,
    search,
    layout,
    video,
    tag
  }
})

export const useStore = (): Store<RootState> => {
  return baseUseStore(key)
}
