import { NavigationGuard } from 'vue-router'
import { axios, ApiResponse } from '@/plugins/axios'
import { store } from '@/store/index'

export const authMiddleware: NavigationGuard = async (to, from, next) => {
  await axios.get<ApiResponse<any>>('/account/user')
    .then(response => {
      store.commit('user/setUser', response.data.payload)
      const is_well_labo = !!response.data.payload.roles.find((element:any) => element.slug === 'well_labo')
      if (is_well_labo){
        next()
      } else {
        window.location.href = `${process.env.VUE_APP_CLIENT_URL}/login`
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        // 元々遷移したかったpathをクエリで渡して、ログイン後に自動的に遷移させる
        // const queryNext: NextQueryItem = {
        //   next: to.path,
        // }
        // store.commit('base/setAlertMessages', { messages: [i18n.global.t(`error.${error.response.status}`)], color: ALERT_COLORS.info, persistent: true })
        // next({ name: 'Login', query: queryNext })
        window.location.href = `${process.env.VUE_APP_CLIENT_URL}/login?next=${to.path}&wl=true`
      } else {
        // next()
        window.location.href = `${process.env.VUE_APP_CLIENT_URL}/login`
      }
    })
}

// export const guestMiddleware: NavigationGuard = (to, from, next) => {
//   if (to.query[alertQueryKeys[0]] === '401') {
//     store.commit('base/setAlertMessages', { messages: [i18n.global.t('error.401')], color: ALERT_COLORS.info, persistent: true })
//   }

//   axios.get('/sanctum/csrf-cookie')
//     .catch((error) => {
//       console.error('/sanctum/csrf-cookie', error)
//     })
//     .finally(() => {
//       next()
//     })
// }
